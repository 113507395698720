import React, { useEffect } from "react";
import { useAppState } from "@state";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const Hero = loadable(() => import("@organisms/Hero"));
const PageBuilder = loadable(() => import("@templates/PageBuilder"));
const EopCta = loadable(() => import("@organisms/EopCta"));
const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));

const HomePage = ({ data }) => {
  const [, dispatch] = useAppState();

  useEffect(() => {
    dispatch({ type: "setTheme", theme: "white" });
  }, []);

  const { hero, pageBuilder } = data;
  return (
    <PageContainer overflow>
      <Hero {...hero} />
      <PageBuilder blocks={pageBuilder} />
      <EopCta />
      <NewsletterSignup />
    </PageContainer>
  );
};

export default HomePage;
